import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Typography, Avatar, Modal } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { styled } from '@mui/system';

/* global fbq */ // Declare fbq as global to resolve the ESLint issue

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const Logo = styled(Avatar)({
  width: 70,
  height: 70,
  marginBottom: '16px',
});

export default function LoginPage() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  // Google Authentication Handler
  const handleGoogleAuth = () => {
    // Track the event when the button is clicked
    fbq('track', 'LoginWithGoogle'); // Custom Meta Pixel event for "Login with Google" click

    const width = 500;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const googleAuthPopup = window.open(
      `${process.env.REACT_APP_BACKEND_URL}/auth/google`,
      'GoogleLogin',
      `width=${width},height=${height},top=${top},left=${left}`
    );

    const receiveMessage = (event) => {
      if (event.origin !== process.env.REACT_APP_BACKEND_URL) return;
      const { token, error } = event.data;

      if (error) {
        // Show modal if access is denied
        if (error.includes('You need to register to get access.')) {
          setOpen(true);
        } else {
          alert(error);
        }
        return;
      }

      if (token) {
        localStorage.setItem('authToken', token);
        checkTokenValidity(token); // Check token validity
      }
    };

    window.addEventListener('message', receiveMessage, false);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  };

  const handleClick = () => {
    navigate('/betaApplication');
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const checkTokenValidity = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (response.ok) {
        const userData = await response.json();
        // Navigate to homepage if the user is valid
        navigate('/homepage');
      } else {

       
      }
    } catch (error) {
    
      console.error('Error verifying token:', error);
      
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      checkTokenValidity(token);
    }
  
    // Updated Meta Pixel Code
    (function(f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function() {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  
    fbq('init', '870571321452289');
    fbq('track', 'PageView');
  
  }, []); // Empty dependency array ensures this runs only once when the component mounts
  
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F3F4F6',
        padding: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 4,
          borderRadius: 4,
          backgroundColor: 'white',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          maxWidth: 700,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 4,
            backgroundColor: 'white',
          }}
        >
          <Avatar
            src="https://typegrow.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fapple-touch-icon.fe07020f.png&w=48&q=75"
            sx={{ width: 50, height: 50 }}
          />
          <Typography variant="h4" sx={{ my: 2 }}>
            Welcome!
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            We'll sign you in or create an account if you don't have one yet.
          </Typography>

          <Button
            startIcon={<GoogleIcon />}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mb: 2 }}
            onClick={handleGoogleAuth}
          >
            Continue with Google
          </Button>
        </Box>
      </Box>

      {/* Modal for Beta Access */}
      <Modal open={open} onClose={handleModalClose}>
        <Box sx={style}>
          <Logo src="https://typegrow.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fapple-touch-icon.fe07020f.png&w=48&q=75" />
          <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            We are in Closed Beta stage as of now.
          </Typography>
          <Typography sx={{ mt: 2, textAlign: 'center' }}>
            If you are interested in being a part of the same, please apply by clicking the button below.
          </Typography>
          <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleClick}>
            Apply Now
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}
