import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import './ModalDelete.css';


const ModalDelete = ({ modalDelete, setModalDelete, docToDelete, setDocToDelete, token, fetchDocuments }) => {

    const handleDeleteDocs = async (docToDelete) => {
        setModalDelete(false); 
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/delete-document`,
                {
                    docId: docToDelete._id,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                }
            );

            if (response.status === 200) {
                setModalDelete(false); 
                alert('Document deleted successfully!');
                fetchDocuments(); 
            } else {
                setModalDelete(false); 
                alert('Failed to delete document. Please try again.'); 
            }
        } catch (error) {
            setModalDelete(false); 
            alert('Error deleting document.');
            // console.log(error);
        } finally {
            setDocToDelete(null);
            setModalDelete(false); 
        }
    };

    const handleCancel = () => {
        setDocToDelete(null);
        setModalDelete(false); 
    };

    return (
        <>
            <Modal show={modalDelete} onHide={handleCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete the document : <br/> <strong>{docToDelete?.title}</strong>?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button variant="secondary" onClick={handleCancel}>
                        No
                    </button>
                    <button variant="danger" onClick={() => handleDeleteDocs(docToDelete)}>
                        Yes
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalDelete;
