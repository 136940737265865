import React from 'react';
import CustomNavbar from './CustomNavbar';
import './Privacy.css'

const Privacy = () => {
    return (
        <>
        <CustomNavbar />
            <div className="privacy-container">
                <h1 className="header">Privacy Policy</h1>
                <p className="subText">Last update: September 18, 2024</p>

                <p className="paragraph">
                    This notice describes how we collect and process user data through magictext.com website and platform (“Platform”).
                    The terms “we”, “us”, and “our” refer to MagicText, a legal person registered under the laws of the Slovak Republic.
                    We are committed to safeguarding the privacy of our users. We will not misuse your data.
                </p>

                <p className="paragraph"><strong>Contact email address:</strong> hello@magictext.com.</p>

                <p className="paragraph">
                    Please note, this privacy notice covers our processing activities as a data controller, including those related to your account,
                    information collected through the website, and our marketing activities.
                </p>

                <p className="paragraph">
                    Whenever you collect and process information from LinkedIn profiles and messages via the platform, our role is that of a data
                    processor on your behalf. You, as the data controller, are responsible for processing LinkedIn data in accordance with applicable
                    data protection requirements. Our obligations as a data processor are outlined in the Data Processing Agreement, which forms an
                    integral part of MagicText's Terms and Conditions.
                </p>

                <h2 className="sectionHeader">Website Visitors</h2>
                <p className="paragraph">
                    Like most website operators, MagicText collects non-personally-identifying information, such as the browser type, language preference,
                    referring site, and the date and time of each visitor request. MagicText may also collect statistics about the behavior of visitors to
                    its websites. MagicText’s purpose in collecting non-personally identifying information is to better understand how visitors use its
                    website. From time to time, MagicText may release non-personally-identifying information in aggregate, for example, by publishing a
                    report on trends in the usage of its website.
                </p>

                <h2 className="sectionHeader">Gathering of Personally-Identifying Information</h2>
                <p className="paragraph">
                    Certain visitors to MagicText’s websites choose to interact with MagicText in ways that require gathering personally-identifying
                    information. The amount and type of information gathered depend on the nature of the interaction. For example, we ask visitors who
                    sign up for an account at MagicText to provide an email address. Those who engage in transactions with MagicText, such as purchasing
                    access to premium services, are asked to provide additional information, including personal and financial details necessary to process
                    transactions. In each case, MagicText collects only the information necessary or appropriate to fulfill the visitor’s interaction
                    with MagicText. MagicText does not disclose personally-identifying information other than as described below, and visitors can always
                    refuse to supply personally-identifying information, though it may prevent them from engaging in certain website-related activities.
                </p>

                <h2 className="sectionHeader">Protection of Certain Personally-Identifying Information</h2>
                <p className="paragraph">
                    MagicText discloses potentially personally-identifying and personally-identifying information only to employees, contractors,
                    and affiliated organizations that:
                </p>
                <ul className="list">
                    <li className="listItem">
                        (i) need to know that information to process it on MagicText's behalf or to provide services available at MagicText's websites, and
                    </li>
                    <li className="listItem">
                        (ii) have agreed not to disclose it to others.
                    </li>
                </ul>
                <p className="paragraph">
                    Some of those employees, contractors, and affiliated organizations may be located outside of your home country; by using MagicText's
                    websites, you consent to the transfer of such information to them. MagicText will not rent or sell potentially personally-identifying
                    and personally-identifying information to anyone. Other than to employees, contractors, and affiliated organizations, MagicText discloses
                    potentially personally-identifying and personally-identifying information in response to a subpoena, court order, or other governmental
                    request, or when MagicText believes in good faith that disclosure is reasonably necessary to protect the property or rights of MagicText,
                    third parties, or the public at large. If you are a registered user of a MagicText website and have provided your email address, MagicText
                    may occasionally send you an email to inform you about new features, solicit your feedback, or keep you updated about MagicText and its
                    products. We primarily use other communication channels for such information, so we expect to keep email notifications to a minimum. If
                    you send us a request (for example, via support email or one of our feedback mechanisms), we reserve the right to publish it to help us
                    clarify or respond to your request or to support other users. MagicText takes all reasonable measures to protect against unauthorized
                    access, use, alteration, or destruction of potentially personally-identifying and personally-identifying information.
                </p>

                <h2 className="sectionHeader">Cookies</h2>
                <p className="paragraph">
                    A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor's browser provides to the website
                    each time the visitor returns. MagicText uses cookies to help identify and track visitors, their usage of the website, and access preferences.
                    Visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using MagicText's
                    websites, with the understanding that certain features may not function properly without the aid of cookies.
                </p>
                <p className="paragraph">MagicText uses Google Analytics features based on Display Advertising. Visitors can opt out of this at any time.</p>

                <h2 className="sectionHeader">Business Transfers</h2>
                <p className="paragraph">
                    If MagicText, or substantially all of its assets, were acquired, or in the unlikely event that MagicText goes out of business or enters
                    bankruptcy, user information would be one of the assets transferred or acquired by a third party. You acknowledge that such transfers may
                    occur, and that any acquirer of MagicText may continue to use your personal information as outlined in this policy.
                </p>

                <h2 className="sectionHeader">Privacy Policy Changes</h2>
                <p className="paragraph">
                    Although most changes are likely to be minor, MagicText may change its Privacy Policy from time to time, at MagicText's sole discretion.
                    We encourage visitors to frequently check this page for any changes. Your continued use of this site after any change in the Privacy Policy
                    will constitute your acceptance of such changes.
                </p>

                <p className="paragraph">
                    Our privacy policy is based on the one from the wonderful team at Automattic. You’re welcome to reuse and modify it for your own purposes,
                    just make sure to replace references to us with your own. Also, consider checking out the original and using WordPress.com for your blog or
                    general CMS needs.
                </p>
            </div>

        </>
    );
};

export default Privacy;
