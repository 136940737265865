import React from 'react';
import './TermsOfService.css'; // Import external CSS
import CustomNavbar from './CustomNavbar';

const TermsOfService = () => {
    return (
        <>
            <CustomNavbar />
            <div className="terms-container">
                <h1>Terms of Service</h1>
                <p>
                    The following terms and conditions govern all use of the MagicText website and all content, services, and
                    products available at magictext.com (collectively, “Website”). The Website is offered subject to your
                    acceptance without modification of all the terms and conditions contained herein and all other operating
                    rules, policies (including, without limitation, MagicText's Privacy Policy), and procedures that may be
                    published from time to time on this website by MagicText (collectively, the “Agreement”).
                </p>
                <p>
                    Please read this Agreement carefully before accessing or using the Website. By accessing or using any part
                    of the Website, you agree to become bound by the terms and conditions of this Agreement. If you do not agree
                    to all the terms and conditions of this Agreement, then you may not access the Website or use any services.
                    If these terms and conditions are considered an offer by MagicText, acceptance is expressly limited to these
                    terms. The Website is available only to individuals who are at least 13 years old.
                </p>
                <h2>Account and Site</h2>
                <p>
                    If you create an account on the Website, you are responsible for maintaining the security of your account
                    and experiments, and you are fully responsible for all activities that occur under the account.
                </p>
                <p>
                    When creating an account, the user needs to provide accurate and complete data. Failing to do so is a breach
                    of the Terms and Conditions stated here. Only personal accounts can be connected to MagicText. Each MagicText
                    account is valid for only one LinkedIn account.
                </p>
                <p>
                    You must immediately notify MagicText of any unauthorized uses of your account or any other breaches of
                    security. The user must not share their password with third parties. MagicText will not be liable for any
                    acts or omissions by You, including any damages of any kind incurred as a result of such acts or omissions.
                </p>
                <p>
                    The users agree not to hack other accounts or damage MagicText's property and MagicText as a product.
                </p>

                <h2>Subscriptions</h2>
                <p>
                    The product MagicText is billed on a subscription basis. The 7-day free trial is applicable only to the first
                    account added to your user account. Adding a second account under the same user, the charges for this account
                    will begin on the date the account is added.
                </p>
                <p>
                    Adding a new seat or removing a seat under an existing subscription will result in a prorated amount being
                    added to your upcoming invoice, and charged on your next billing date, with an amount proportional to the
                    date when the seat was added or removed.
                </p>
                <p>
                    Once you choose your subscription, the amount due will be collected immediately at the time you subscribe.
                    Unless canceled or terminated, the subscription will be automatically renewed for subsequent periods of the
                    same duration as chosen initially. Depending on the subscription period you have chosen, the subsequently due
                    amounts will be collected periodically, each time at the beginning of a new subscription period, on the same
                    day of the month as the initial amount was collected, for as long as the subscription is not canceled or
                    terminated. If you cancel or terminate the subscription without deleting your account, you may resume the
                    subscription under the same conditions, while retaining the data stored.
                </p>
                <p>
                    The subscription is monthly and the payment is collected at the time of subscribing and after the first month
                    on the same date every following month. After being renewed, the subscription will continue under the same
                    conditions as the previous month.
                </p>
                <p>
                    The payment method needs to be valid, otherwise, the subscription will be terminated. The customer should
                    provide accurate and complete billing information. Once provided with the billing information, MagicText is
                    authorized to and will automatically collect the fee for the subscription every month. The date of fee
                    collection cannot be changed.
                </p>
                <p>
                    The subscription will last until it’s terminated by the customer or MagicText. It can be terminated at any
                    given moment and can be renewed immediately after.
                </p>

                <h2>Subscription Fee Changes</h2>
                <p>
                    MagicText keeps its right to change the amount of the fee at any time. The users will be informed in advance
                    and will be given the choice to terminate their subscription or continue using the product with the new
                    price of the subscription.
                </p>

                <h2>Refunds</h2>
                <p>
                    Unless required by law, the subscription fees are non-refundable.
                </p>

                <h2>Responsibility of Contributors</h2>
                <p>
                    If you post material to the Website, post links on the Website, or otherwise make (or allow any third party
                    to make) material available by means of the Website (any such material, "Content"), You are entirely
                    responsible for the content of, and any harm resulting from, that Content. That is the case regardless of
                    whether the Content in question constitutes text, graphics, an audio file, or computer software. By making
                    Content available, you represent and warrant that:
                </p>
                <ul>
                    <li>
                        The downloading, copying, and use of the Content will not infringe the proprietary rights, including but
                        not limited to the copyright, patent, trademark, or trade secret rights of any third party;
                    </li>
                    <li>
                        If your employer has rights to intellectual property you create, you have either (i) received permission
                        from your employer to post or make available the Content, including but not limited to any software, or
                        (ii) secured from your employer a waiver as to all rights in or to the Content;
                    </li>
                    <li>
                        You have fully complied with any third-party licenses relating to the Content and have done all things
                        necessary to successfully pass through to end users any required terms;
                    </li>
                    <li>
                        The Content does not contain or install any viruses, worms, malware, Trojan horses, or other harmful or
                        destructive content;
                    </li>
                    <li>
                        The Content is not spam, is not machine- or randomly-generated, and does not contain unethical or unwanted
                        commercial content designed to drive traffic to third-party sites, or to further unlawful acts (such as
                        phishing) or mislead recipients as to the source of the material (such as spoofing);
                    </li>
                    <li>
                        The Content is not pornographic, does not contain threats or incite violence, and does not violate the
                        privacy or publicity rights of any third party;
                    </li>
                </ul>

                <h2>LinkedIn</h2>
                <p>
                    MagicText is not authorized as an official feature of LinkedIn. MagicText does not take responsibility for
                    the practices and policies of LinkedIn.
                </p>
                <p>
                    MagicText is not responsible for the personal account of the user or what purposes the product is used for.
                    MagicText is not responsible or liable, in case of a banning of the user’s account due to the activities
                    executed by the user.
                </p>
            </div>
        </>
    );
};

export default TermsOfService;
