import React from 'react';
import './DataProcessingAgreement.css';
import CustomNavbar from './CustomNavbar';

const DataProcessingAgreement = () => {
    return (
        <>
            <CustomNavbar />
            <div className="data-processing-container">
                <h1 className="data-processing-title">Data Processing Agreement</h1>
                <p className="paragraph">
                    This Data Processing Agreement (the "DPA"), entered into by the MagicText customer identified on the applicable MagicText ordering document for MagicText services ("Customer") and the MagicText company identified on the ordering document ("MagicText"), governs the processing of personal data that Customer uploads or otherwise provides MagicText in connection with the services and the processing of any personal data that MagicText uploads or otherwise provides to Customer in connection with the services.
                </p>

                <h2 className="section-title">1. Definitions</h2>
                <p className="paragraph"><span className="term">"Customer Personal Data"</span> means Personal Data (i) that Customer uploads or otherwise provides MagicText in connection with its use of MagicText’s services or (ii) for which Customer is otherwise a data controller.</p>
                <p className="paragraph"><span className="term">"Data Controller"</span> means Customer.</p>
                <p className="paragraph"><span className="term">"Data Processor"</span> means MagicText.</p>
                <p className="paragraph"><span className="term">"Data Protection Requirements"</span> means the Directive, the General Data Protection Regulation, Local Data Protection Laws, any subordinate legislation and regulation implementing the General Data Protection Regulation, and all Privacy Laws.</p>
                <p className="paragraph"><span className="term">"Directive"</span> means the EU Data Protection Directive 95/46/EC.</p>
                <p className="paragraph"><span className="term">"EU Personal Data"</span> means Personal Data the sharing of which pursuant to this Agreement is regulated by the Directive, the General Data Protection Regulation and Local Data Protection Laws.</p>
                <p className="paragraph"><span className="term">"General Data Protection Regulation"</span> means the European Union Regulation on the protection of individuals with regard to the processing of personal data and on the free movement of such data.</p>
                <p className="paragraph"><span className="term">"Personal Data"</span> means information about an individual that (a) can be used to identify, contact or locate a specific individual, including data that Customer chooses to provide to MagicText from services such as applicant tracking systems (ATSs) or customer-relationships management (CRM) services; (b) can be combined with other information that can be used to identify, contact or locate a specific individual; or (c) is defined as "personal data" or "personal information" by applicable laws or regulations relating to the collection, use, storage or disclosure of information about an identifiable individual.</p>
                <p className="paragraph"><span className="term">"Personal Data Breach"</span> means any accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to Customer Personal Data.</p>
                <p className="paragraph"><span className="term">"Privacy Laws"</span> means all applicable laws, regulations, and other legal requirements relating to (a) privacy, data security, consumer protection, marketing, promotion, and text messaging, email, and other communications; and (b) the use, collection, retention, storage, security, disclosure, transfer, disposal, and other processing of any Personal Data.</p>

                <h2 className="section-title">2. Nature of Data Processing</h2>
                <p className="paragraph">
                    Each party agrees to process Personal Data received under the Agreement only for the purposes set forth in the Agreement. For the avoidance of doubt, the categories of Personal Data processed and the categories of data subjects subject to this DPA are described in Schedule A to this DPA.
                </p>

                <h2 className="section-title">3. Compliance with Laws</h2>
                <p className="paragraph">
                    The parties shall each comply with their respective obligations under all applicable Data Protection Requirements.
                </p>

                <h2 className="section-title">4. Customer Obligations</h2>
                <ul className="list">
                    <li>Provide instructions to MagicText and determine the purposes and general means of MagicText’s processing of Customer Personal Data in accordance with the Agreement.</li>
                    <li>Comply with its protection, security, and other obligations with respect to Customer Personal Data prescribed by Data Protection Requirements for data controllers.</li>
                    <li>Ensure compliance with the provisions of this Agreement by its personnel or any third-party accessing Customer Personal Data on its behalf.</li>
                </ul>

                <h2 className="section-title">5. MagicText Obligations</h2>
                <p className="paragraph">
                    MagicText will process Customer Personal Data only for the purpose of providing, supporting, and improving MagicText’s services, using appropriate technical and organizational security measures.
                </p>

                <h2 className="section-title">6. Data Return and Deletion</h2>
                <p className="paragraph">
                    Upon termination of the data processing services or upon Customer’s request, MagicText shall return all Customer Personal Data or securely destroy them, unless Data Protection Requirements prevent MagicText from returning or destroying the data.
                </p>

                <h2 className="section-title">7. Governing Law, Jurisdiction, and Venue</h2>
                <p className="paragraph">
                    This DPA shall be governed by the laws of the Slovak Republic, and any action or proceeding related to this DPA will be brought in Slovak Republic.
                </p>

                <h2 className="section-title">8. List of Sub-Processors</h2>
                <ul className="list">
                    <li>Amazon Web Services, Inc.</li>
                    <li>Cloudflare, Inc.</li>
                    <li>Google, Inc.</li>
                    <li>Bright Data Ltd.</li>
                </ul>
            </div>
        </>

    );
};

export default DataProcessingAgreement;
