// import React from 'react';
// import axios from 'axios';
// import {
//   TextField,
//   Checkbox,
//   FormControlLabel,
//   Button,
//   Typography,
//   Container,
//   Box,
// } from '@mui/material';
// import { useForm } from 'react-hook-form';

// const BetaProgramForm = () => {
//   const { register, handleSubmit, formState: { errors } } = useForm();

//   const onSubmit = async (data) => {
//     console.log(data);
//     try {
//       // Prepare the submission data
//       const submissionData = {
//         googleEmail: data.email,
//         name: data.name,
//         niche: data.niche,
//         linkedinProfile: data.linkedin,
//         privacyPolicy: data.agree, // Use agree for privacy policy
//         motivation: data.motivation, // Include motivation
//       };
  
//       // Send the data to your backend
//       const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/askforaccess`, submissionData);
  
//       // Handle the response from the backend
//       if (response.status === 201) { // 201 is the status code for created
//         alert(response.data.message); // Assuming your success message is in the 'message' field
//         console.log('Access request submitted successfully:', response.data);
//         // Optionally reset the form or display a success message
//       }
//     } catch (error) {
//       console.error('Error submitting access request:', error);
  
//       // Check if the error response exists and has a message
//       if (error.response && error.response.data && error.response.data.message) {
//         alert(error.response.data.message); // Display the error message from the backend
//       } else {
//         alert("An unexpected error occurred. Please try again."); // Generic error message
//       }
//     }
//   };
  
//   return (
//     <Container component="main" maxWidth="xs">
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           justifyContent: 'center',
//           mt: 8,
//           p: 3,
//           borderRadius: '10px',
//           boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
//         }}
//       >
//         <Typography variant="h5" gutterBottom>
//           Join the Beta Program
//         </Typography>

//         <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
//           <TextField
//             {...register('email', { required: 'Email is required' })}
//             label="Google Email Address"
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             error={!!errors.email}
//             helperText={errors.email ? errors.email.message : ''}
//           />
//           <TextField
//             {...register('name', { required: 'Name is required' })}
//             label="Name"
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             error={!!errors.name}
//             helperText={errors.name ? errors.name.message : ''}
//           />
//           <TextField
//             {...register('niche', { required: 'Niche is required' })}
//             label="Niche"
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             error={!!errors.niche}
//             helperText={errors.niche ? errors.niche.message : ''}
//           />
//           <TextField
//             {...register('linkedin', { required: 'LinkedIn Profile Link is required' })}
//             label="LinkedIn Profile Link"
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             error={!!errors.linkedin}
//             helperText={errors.linkedin ? errors.linkedin.message : ''}
//           />
//           <TextField
//             {...register('motivation', { required: 'Your motivation to join the beta program is required' })}
//             label="Your motivation to join the beta program"
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             multiline
//             rows={4}
//             error={!!errors.motivation}
//             helperText={errors.motivation ? errors.motivation.message : ''}
//           />
//           <FormControlLabel
//             control={
//               <Checkbox {...register('agree', { required: true })} color="primary" />
//             }
//             label="I agree to be contacted by Abhay Chauhan to collect feedback by email or personal messages."
//           />
//           {errors.agree && (
//             <Typography color="error" variant="body2">
//               You must agree to continue.
//             </Typography>
//           )}
//           <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             fullWidth
//             sx={{ mt: 2 }}
//           >
//             Submit
//           </Button>
//         </form>
//       </Box>
//     </Container>
//   );
// };

// export default BetaProgramForm;




















// import React, { useState } from 'react';
// import axios from 'axios';
// import {
//   TextField,
//   Checkbox,
//   FormControlLabel,
//   Button,
//   Typography,
//   Container,
//   Box,
//   Snackbar,
//   Alert
// } from '@mui/material';
// import { useForm } from 'react-hook-form';

// const BetaProgramForm = () => {
//   const { register, handleSubmit, formState: { errors } } = useForm();
//   const [openSnackbar, setOpenSnackbar] = useState(false);  // Manage Snackbar state
//   const [snackbarMessage, setSnackbarMessage] = useState('');  // Snackbar message
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Snackbar type (success or error)

//   const handleCloseSnackbar = () => {
//     setOpenSnackbar(false);
//   };

//   const onSubmit = async (data) => {
//     console.log(data);
//     try {
//       const submissionData = {
//         googleEmail: data.email,
//         name: data.name,
//         niche: data.niche,
//         linkedinProfile: data.linkedin,
//         privacyPolicy: data.agree,
//         motivation: data.motivation,
//       };

//       // Send data to backend
//       const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/askforaccess`, submissionData);

//       // If successful, show success notification
//       if (response.status === 201) {
//         setSnackbarMessage(response.data.message);
//         setSnackbarSeverity('success');
//         setOpenSnackbar(true);
//       }
//     } catch (error) {
//       console.error('Error submitting access request:', error);

//       // Show error notification
//       if (error.response && error.response.data && error.response.data.message) {
//         setSnackbarMessage(error.response.data.message);
//       } else {
//         setSnackbarMessage('An unexpected error occurred. Please try again.');
//       }
//       setSnackbarSeverity('error');
//       setOpenSnackbar(true);
//     }
//   };

//   return (
//     <Container component="main" maxWidth="xs">
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           justifyContent: 'center',
//           mt: 8,
//           p: 3,
//           borderRadius: '10px',
//           boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
//         }}
//       >
//         <Typography variant="h5" gutterBottom>
//           Join the Beta Program
//         </Typography>

//         <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
//           <TextField
//             {...register('email', { required: 'Email is required' })}
//             label="Google Email Address"
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             error={!!errors.email}
//             helperText={errors.email ? errors.email.message : ''}
//           />
//           <TextField
//             {...register('name', { required: 'Name is required' })}
//             label="Name"
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             error={!!errors.name}
//             helperText={errors.name ? errors.name.message : ''}
//           />
//           <TextField
//             {...register('niche', { required: 'Niche is required' })}
//             label="Niche"
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             error={!!errors.niche}
//             helperText={errors.niche ? errors.niche.message : ''}
//           />
//           <TextField
//             {...register('linkedin', { required: 'LinkedIn Profile Link is required' })}
//             label="LinkedIn Profile Link"
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             error={!!errors.linkedin}
//             helperText={errors.linkedin ? errors.linkedin.message : ''}
//           />
//           <TextField
//             {...register('motivation', { required: 'Your motivation to join the beta program is required' })}
//             label="Your motivation to join the beta program"
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             multiline
//             rows={4}
//             error={!!errors.motivation}
//             helperText={errors.motivation ? errors.motivation.message : ''}
//           />
//           <FormControlLabel
//             control={
//               <Checkbox {...register('agree', { required: true })} color="primary" />
//             }
//             label="I agree to be contacted by Abhay Chauhan to collect feedback by email or personal messages."
//           />
//           {errors.agree && (
//             <Typography color="error" variant="body2">
//               You must agree to continue.
//             </Typography>
//           )}
//           <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             fullWidth
//             sx={{ mt: 2 }}
//           >
//             Submit
//           </Button>
//         </form>
//       </Box>

//       {/* Snackbar for notifications */}
//       <Snackbar
//         open={openSnackbar}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//       >
//         <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </Container>
//   );
// };

// export default BetaProgramForm;







import React, { useState } from 'react';
import axios from 'axios';
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Container,
  Box,
  Snackbar,
  Alert,
  CircularProgress // Import CircularProgress for the spinner
} from '@mui/material';
import { useForm } from 'react-hook-form';

const BetaProgramForm = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [openSnackbar, setOpenSnackbar] = useState(false);  // Manage Snackbar state
  const [snackbarMessage, setSnackbarMessage] = useState('');  // Snackbar message
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Snackbar type (success or error)
  const [loading, setLoading] = useState(false);  // Manage loading state

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const onSubmit = async (data) => {
    //console.log(data);
    setLoading(true); // Start loading when form is submitted
    try {
      const submissionData = {
        googleEmail: data.email,
        name: data.name,
        niche: data.niche,
        linkedinProfile: data.linkedin,
        privacyPolicy: data.agree,
        motivation: data.motivation,
      };

      // Send data to backend
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/askforaccess`, submissionData);

      // If successful, show success notification
      if (response.status === 201) {
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
      }
    } catch (error) {
    //  console.error('Error submitting access request:', error);

      // Show error notification
      if (error.response && error.response.data && error.response.data.message) {
        setSnackbarMessage(error.response.data.message);
      } else {
        setSnackbarMessage('An unexpected error occurred. Please try again.');
      }
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setLoading(false); // Stop loading after request completes
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 8,
          p: 3,
          borderRadius: '10px',
          boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="h5" gutterBottom>
          Join the Beta Program
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <TextField
            {...register('email', { required: 'Email is required' })}
            label="Google Email Address"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ''}
          />
          <TextField
            {...register('name', { required: 'Name is required' })}
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.name}
            helperText={errors.name ? errors.name.message : ''}
          />
          <TextField
            {...register('niche', { required: 'Niche is required' })}
            label="Niche"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.niche}
            helperText={errors.niche ? errors.niche.message : ''}
          />
          <TextField
            {...register('linkedin', { required: 'LinkedIn Profile Link is required' })}
            label="LinkedIn Profile Link"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.linkedin}
            helperText={errors.linkedin ? errors.linkedin.message : ''}
          />
          <TextField
            {...register('motivation', { required: 'Your motivation to join the beta program is required' })}
            label="Your motivation to join the beta program"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            error={!!errors.motivation}
            helperText={errors.motivation ? errors.motivation.message : ''}
          />
          <FormControlLabel
            control={
              <Checkbox {...register('agree', { required: true })} color="primary" />
            }
            label="I agree to be contacted by Abhay Chauhan to collect feedback by email or personal messages."
          />
          {errors.agree && (
            <Typography color="error" variant="body2">
              You must agree to continue.
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            disabled={loading}  // Disable button while loading
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}  {/* Show spinner or text */}
          </Button>
        </form>
      </Box>

      {/* Snackbar for notifications */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default BetaProgramForm;
