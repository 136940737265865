
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import CustomNavbar from './components/CustomNavbar';
import HomePage from './components/Homepage';
import CombinedPanel from './components/CombinedPanel';
import LoginPage from './components/LoginPage';
import BetaProgramForm from './components/BetaProgramForm';
import Privacy from './components/Privacy';
import DataProcessingAgreement from './components/DataProcessingAgreement';
import TermsOfService from './components/TermsOfService';
import HelpSupport from './components/HelpSupport';
import './App.css';

const App = () => {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};

const AppRoutes = () => {
  const location = useLocation();
  
  // Check if the user is authenticated
  const isAuthenticated = () => {
    const token = localStorage.getItem('authToken');
    return token !== null;
  };

  // Redirect to homepage if authenticated
  useEffect(() => {
    if (isAuthenticated() && location.pathname === '/') {
      window.location.href = '/homepage';
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/betaApplication" element={<BetaProgramForm />} />
      <Route path="/homepage" element={isAuthenticated() ? <HomePage /> : <Navigate to="/" />} />
      <Route path="/user" element={isAuthenticated() ? <CombinedPanel /> : <Navigate to="/" />} />
      <Route path="/response/:promptId" element={isAuthenticated() ? <CombinedPanel /> : <Navigate to="/" />} />
    <Route path="/privacy" element={<Privacy/>} />
    <Route path="/dpa" element={<DataProcessingAgreement/>} />
    <Route path="/terms-of-service" element={<TermsOfService/>} />
    <Route path="/help&support" element={< HelpSupport/>} />
    </Routes>
  );
};

export default App;