
import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa'; 
import './ModalDoc.css';

const ModalDoc = ({ modalSave, setModalSave }) => {
    const [documentName, setDocumentName] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false); 
    const navigate = useNavigate();
    const token = localStorage.getItem('authToken');

    const handleOk = async () => {
        const defaultDocumentName = documentName.trim() === '' ? '' : documentName;
        if (defaultDocumentName === '') {
            alert("Document name can't be empty");
            return;
        }

        setLoading(true); 

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/save-document`, {
                documentName: defaultDocumentName,
                content: '',
                description: description,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Include the token in the headers
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });

            if (response.status === 200) {
                setLoading(false); 
                setModalSave(false);
                alert('Document created successfully!');
                localStorage.setItem("docId",response.data.Id);
                navigate('/user', {
                    state: {
                        doc: {
                            title: defaultDocumentName,
                            content: '',
                            description: description,
                            ID: response.data.Id,
                        }
                    }
                });
            } else {
                alert('Failed to create document. Please try again.');
            }
        } catch (error) {
            // console.error('Error creating document:', error);
            alert('Error creating document. Please check the console for more details.');
        } finally {
            setLoading(false); 
        }
    };

    const handleCancel = () => {
        setModalSave(false);
        setDocumentName('');
        setDescription('');
    };

    return (
        <>
            <Modal show={modalSave} onHide={() => handleCancel()}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="documentName">
                        <Form.Label>Document Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={documentName}
                            onChange={(e) => setDocumentName(e.target.value)}
                            placeholder="Enter document name"
                        />
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Enter description"
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        variant="secondary"
                        className="cancel-button"
                        onClick={handleCancel}
                        disabled={loading} 
                    >
                        Cancel
                    </button>
                    <button
                        variant="primary"
                        className="save-button"
                        onClick={handleOk}
                        disabled={loading} 
                    >
                        {loading ? <FaSpinner className="loading-icon" /> : 'Ok'}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalDoc;

