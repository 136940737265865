
// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import './Toolbar.css';
// import axios from 'axios'; 
// import { Spinner } from 'react-bootstrap';

// const Toolbar = ({
//   documentName,
//   setDocumentName,
//   editorContent,
//   setEditorContent,
//   docId,
//   setDocId,
// }) => {
//   const navigate = useNavigate();
//   const [documents, setDocuments] = useState([]);
//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedDocument, setSelectedDocument] = useState(documentName || 'Select Document');
//   const [loading, setLoading] = useState(false); 

//     const quillRef = React.useRef(null);


//   const token = localStorage.getItem('authToken');

//   useEffect(() => {
//     fetchDocuments();
//   }, []);

//   useEffect(() => {
//     setSelectedDocument(documentName);
//   }, [documentName]);

//   const handleSaveDocument = async () => {
//     setLoading(true); // Set loading to true
//     try {
//       const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/save-document`, {
//         documentName : selectedDocument,
//         content: editorContent,
//         docId,
//       }, {
//         headers: {
//           'Authorization': `Bearer ${token}`,
//           'Content-Type': 'application/json',
//         },
//         withCredentials: true,
//       });

//       if (response.status === 200) {
//         fetchDocuments();
//         alert('Document saved successfully!');
//       } else {
//         alert('Failed to save document. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error saving document:', error);
//       alert('Error saving document. Please check the console for more details.');
//     } finally {
//       setLoading(false); 
//     }
//   };

//   const handleOptionClick = (doc) => {
//     setSelectedDocument(doc.title);
//     handleDocumentChange(doc);
//     setIsOpen(false);
//   };

//   const fetchDocuments = async () => {
//     try {
//       const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/documents`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           'Content-Type': 'application/json',
//         },
//         credentials: 'include',
//       });

//       if (!response.ok) {
//         throw new Error('Failed to fetch documents');
//       }

//       const data = await response.json();
//       setDocuments(data.documents);
//     } catch (error) {
//       console.error('Error fetching documents:', error);
//     }
//   };

//   const handleDocumentChange = (selectedDoc) => {
//     if (selectedDoc) {
//       setDocId(selectedDoc._id);
//       setDocumentName(selectedDoc.title);
//       setEditorContent(selectedDoc.content);
//     }
//   };

//   const filteredDocuments = documents.filter(
//     (doc) => doc.title !== selectedDocument
//   );


//   return (
//     <div id="toolbar">
//       <div className="custom-select-container" style={{ position: 'relative', zIndex: 100 }}>
//         <div className="custom-select-header" onClick={() => setIsOpen(!isOpen)}>
//           {/* {selectedDocument || 'Select Document'} */}
//           <input 
//           type='text' 
//           value={selectedDocument}
//           onChange={(e) => setSelectedDocument(e.target.value)}
//           placeholder='Select the document' />
//           <span className={`dropdown-arrow ${isOpen ? 'up' : 'down'}`}></span>
//         </div>
//         {isOpen && (
//           <div className="custom-select-dropdown">
//             {filteredDocuments.length > 0 ? (
//               filteredDocuments.map((doc) => (
//                 <div key={doc._id} className="custom-select-item" onClick={() => handleOptionClick(doc)}>
//                   {doc.title}
//                 </div>
//               ))
//             ) : (
//               <div className="custom-select-item">No other documents</div>
//             )}
//           </div>
//         )}
//       </div>

//       <div className='vertical-line'></div>

//       <div className="ql-dashboard effects" onClick={() => navigate('/homepage')}>Dashboard</div>

//       <div className='vertical-line'></div>

//       <button className="ql-save effects" onClick={loading ? null : handleSaveDocument}>
//         {loading ? <Spinner animation="border" size="sm" /> : <>Save</>}
//       </button>


//       <div className='vertical-line'></div>
//       <button className="btn" >Bold</button>
//       <button className="ql-italic btn">Italic</button>
//       <button className="ql-underline btn">Underline</button>
//       <button className="ql-strike btn">Strike</button>
//       <button className="ql-blockquote btn">Blockquote</button>
//       <button className="ql-list btn" value="ordered">Ordered List</button>
//       <button className="ql-list btn" value="bullet">Bullet List</button>
//     </div>
//   );
// };

// export default Toolbar;


import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Toolbar.css';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { MdFormatListNumbered } from "react-icons/md";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListOl, faListUl } from '@fortawesome/free-solid-svg-icons';

const Toolbar = ({
  documentName,
  setDocumentName,
  editorContent,
  setEditorContent,
  docId,
  setDocId,
  quillRef
}) => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(documentName || 'Select Document');
  const [loading, setLoading] = useState(false);


  const token = localStorage.getItem('authToken');

  useEffect(() => {
    fetchDocuments();
  }, []);


  useEffect(() => {
    setSelectedDocument(documentName);
  }, [documentName]);

  const handleOptionClick = (doc) => {
    setSelectedDocument(doc.title);
    handleDocumentChange(doc);
    setIsOpen(false);
  };

  const handleSaveDocument = async () => {
    setLoading(true); // Set loading to true
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/save-document`, {
        documentName: selectedDocument,
        content: editorContent,
        docId,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      if (response.status === 200) {
        fetchDocuments();
        alert('Document saved successfully!');
      } else {
        alert('Failed to save document. Please try again.');
      }
    } catch (error) {
      console.error('Error saving document:', error);
      alert('Error saving document.');
    } finally {
      setLoading(false);
    }
  };

  const fetchDocuments = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/documents`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to fetch documents');
      }

      const data = await response.json();
      setDocuments(data.documents);

      const storedId = localStorage.getItem("docId");
      if (storedId) {
        const foundDoc = (data.documents).find(doc => doc._id === storedId);
        // console.log("found doc",foundDoc);
        setSelectedDocument(foundDoc.title);
        handleDocumentChange(foundDoc);
      }
    } catch (error) {
      // console.error('Error fetching documents:', error);
    }
  };

  const handleDocumentChange = (selectedDoc) => {
    if (selectedDoc) {
      localStorage.setItem("docId", selectedDoc._id)
      setDocId(selectedDoc._id);
      setDocumentName(selectedDoc.title);
      setEditorContent(selectedDoc.content);
    }
  };

  const filteredDocuments = documents.filter(
    (doc) => doc.title !== selectedDocument
  );

  const boldDigits = { '0': '𝟬', '1': '𝟭', '2': '𝟮', '3': '𝟯', '4': '𝟰', '5': '𝟱', '6': '𝟲', '7': '𝟳', '8': '𝟴', '9': '𝟵' };

  const boldMap = {
    '0': '𝟬', '1': '𝟭', '2': '𝟮', '3': '𝟯', '4': '𝟰', '5': '𝟱', '6': '𝟲', '7': '𝟳', '8': '𝟴', '9': '𝟵',
    'a': '𝗮', 'b': '𝗯', 'c': '𝗰', 'd': '𝗱', 'e': '𝗲', 'f': '𝗳', 'g': '𝗴',
    'h': '𝗵', 'i': '𝗶', 'j': '𝗷', 'k': '𝗸', 'l': '𝗹', 'm': '𝗺', 'n': '𝗻',
    'o': '𝗼', 'p': '𝗽', 'q': '𝗾', 'r': '𝗿', 's': '𝘀', 't': '𝘁', 'u': '𝘂',
    'v': '𝘃', 'w': '𝘄', 'x': '𝘅', 'y': '𝘆', 'z': '𝘇',
    'A': '𝗔', 'B': '𝗕', 'C': '𝗖', 'D': '𝗗', 'E': '𝗘', 'F': '𝗙', 'G': '𝗚',
    'H': '𝗛', 'I': '𝗜', 'J': '𝗝', 'K': '𝗞', 'L': '𝗟', 'M': '𝗠', 'N': '𝗡',
    'O': '𝗢', 'P': '𝗣', 'Q': '𝗤', 'R': '𝗥', 'S': '𝗦', 'T': '𝗧', 'U': '𝗨',
    'V': '𝗩', 'W': '𝗪', 'X': '𝗫', 'Y': '𝗬', 'Z': '𝗭'
  };
  const reverseBoldMap2 = {
    '𝟬': '0', '𝟭': '1', '𝟮': '2', '𝟯': '3', '𝟰': '4', '𝟱': '5', '𝟲': '6', '𝟳': '7', '𝟴': '8', '𝟵': '9',
    '𝗮': 'a', '𝗯': 'b', '𝗰': 'c', '𝗱': 'd', '𝗲': 'e', '𝗳': 'f', '𝗴': 'g',
    '𝗵': 'h', '𝗶': 'i', '𝗷': 'j', '𝗸': 'k', '𝗹': 'l', '𝗺': 'm', '𝗻': 'n',
    '𝗼': 'o', '𝗽': 'p', '𝗾': 'q', '𝗿': 'r', '𝘀': 's', '𝘁': 't', '𝘂': 'u',
    '𝘃': 'v', '𝘄': 'w', '𝘅': 'x', '𝘆': 'y', '𝘇': 'z',
    '𝗔': 'A', '𝗕': 'B', '𝗖': 'C', '𝗗': 'D', '𝗘': 'E', '𝗙': 'F', '𝗚': 'G',
    '𝗛': 'H', '𝗜': 'I', '𝗝': 'J', '𝗞': 'K', '𝗟': 'L', '𝗠': 'M', '𝗡': 'N',
    '𝗢': 'O', '𝗣': 'P', '𝗤': 'Q', '𝗥': 'R', '𝗦': 'S', '𝗧': 'T', '𝗨': 'U',
    '𝗩': 'V', '𝗪': 'W', '𝗫': 'X', '𝗬': 'Y', '𝗭': 'Z'
  };
  const reverseBoldMap = {
    57324: '0', 57325: '1', 57326: '2', 57327: '3', 57328: '4', 57329: '5', 57330: '6', 57331: '7', 57332: '8', 57333: '9',
    56814: 'a', 56815: 'b', 56816: 'c', 56817: 'd', 56818: 'e', 56819: 'f', 56820: 'g',
    56821: 'h', 56822: 'i', 56823: 'j', 56824: 'k', 56825: 'l', 56826: 'm', 56827: 'n',
    56828: 'o', 56829: 'p', 56830: 'q', 56831: 'r', 56832: 's', 56833: 't', 56834: 'u',
    56835: 'v', 56836: 'w', 56837: 'x', 56838: 'y', 56839: 'z',
    56788: 'A', 56789: 'B', 56790: 'C', 56791: 'D', 56792: 'E', 56793: 'F', 56794: 'G',
    56795: 'H', 56796: 'I', 56797: 'J', 56798: 'K', 56799: 'L', 56800: 'M', 56801: 'N',
    56802: 'O', 56803: 'P', 56804: 'Q', 56805: 'R', 56806: 'S', 56807: 'T', 56808: 'U',
    56809: 'V', 56810: 'W', 56811: 'X', 56812: 'Y', 56813: 'Z'
  };

  const reverseItalicMap = {
    56398: 'a', 56399: 'b', 56400: 'c', 56401: 'd', 56402: 'e', 56403: 'f', 56404: 'g',
    56405: 'h', 56406: 'i', 56407: 'j', 56408: 'k', 56409: 'l', 56410: 'm', 56411: 'n',
    56412: 'o', 56413: 'p', 56414: 'q', 56415: 'r', 56416: 's', 56417: 't', 56418: 'u',
    56419: 'v', 56420: 'w', 56421: 'x', 56422: 'y', 56423: 'z',
    56372: 'A', 56373: 'B', 56374: 'C', 56375: 'D', 56376: 'E', 56377: 'F', 56378: 'G',
    56379: 'H', 56380: 'I', 56381: 'J', 56382: 'K', 56383: 'L', 56384: 'M', 56385: 'N',
    56386: 'O', 56387: 'P', 56388: 'Q', 56389: 'R', 56390: 'S', 56391: 'T', 56392: 'U',
    56393: 'V', 56394: 'W', 56395: 'X', 56396: 'Y', 56397: 'Z'
  };
  const underlineCharMap = {
    'a': '𝚊̲', 'b': '𝚋̲', 'c': '𝚌̲', 'd': '𝚍̲', 'e': '𝚎̲', 'f': '𝚏̲', 'g': '𝚐̲', 'h': '𝚑̲', 'i': '𝚒̲', 'j': '𝚓̲',
    'k': '𝚔̲', 'l': '𝚕̲', 'm': '𝚖̲', 'n': '𝚗̲', 'o': '𝚘̲', 'p': '𝚙̲', 'q': '𝚚̲', 'r': '𝚛̲', 's': '𝚜̲', 't': '𝚝̲',
    'u': '𝚞̲', 'v': '𝚟̲', 'w': '𝚠̲', 'x': '𝚡̲', 'y': '𝚢̲', 'z': '𝚣̲',
    'A': '𝙰̲', 'B': '𝙱̲', 'C': '𝙲̲', 'D': '𝙳̲', 'E': '𝙴̲', 'F': '𝙵̲', 'G': '𝙶̲', 'H': '𝙷̲', 'I': '𝙸̲', 'J': '𝙹̲',
    'K': '𝙺̲', 'L': '𝙻̲', 'M': '𝙼̲', 'N': '𝙽̲', 'O': '𝙾̲', 'P': '𝙿̲', 'Q': '𝚀̲', 'R': '𝚁̲', 'S': '𝚂̲', 'T': '𝚃̲',
    'U': '𝚄̲', 'V': '𝚅̲', 'W': '𝚆̲', 'X': '𝚇̲', 'Y': '𝚈̲', 'Z': '𝚉̲',
    '0': '𝟶̲', '1': '𝟷̲', '2': '𝟸̲', '3': '𝟹̲', '4': '𝟺̲', '5': '𝟻̲', '6': '𝟼̲', '7': '𝟽̲', '8': '𝟾̲', '9': '𝟿̲',
    '!': '!̲', '@': '@̲', '#': '#̲', '$': '$̲', '%': '%̲', '^': '^̲', '&': '&̲', '*': '*̲', '(': '(̲', ')': ')̲',
    '-': '–̲', '_': '_̲', '=': '=̲', '+': '+̲', '[': '[̲', ']': ']̲', '{': '{̲', '}': '}̲', '|': '|̲',
    ';': ';̲', ':': ':̲', '\'': '\'̲', '"': '"̲', ',': ',̲', '.': '.̲', '<': '<̲', '>': '>̲', '?': '?̲',
    '/': '/̲', '`': '`̲', '~': '~̲', '\\': '\\̲'
  };
  const reverseUnderlineCharMap = {
    '𝚊̲': 'a', '𝚋̲': 'b', '𝚌̲': 'c', '𝚍̲': 'd', '𝚎̲': 'e', '𝚏̲': 'f', '𝚐̲': 'g', '𝚑̲': 'h', '𝚒̲': 'i', '𝚓̲': 'j',
    '𝚔̲': 'k', '𝚕̲': 'l', '𝚖̲': 'm', '𝚗̲': 'n', '𝚘̲': 'o', '𝚙̲': 'p', '𝚚̲': 'q', '𝚛̲': 'r', '𝚜̲': 's', '𝚝̲': 't',
    '𝚞̲': 'u', '𝚟̲': 'v', '𝚠̲': 'w', '𝚡̲': 'x', '𝚢̲': 'y', '𝚣̲': 'z',
    '𝙰̲': 'A', '𝙱̲': 'B', '𝙲̲': 'C', '𝙳̲': 'D', '𝙴̲': 'E', '𝙵̲': 'F', '𝙶̲': 'G', '𝙷̲': 'H', '𝙸̲': 'I', '𝙹̲': 'J',
    '𝙺̲': 'K', '𝙻̲': 'L', '𝙼̲': 'M', '𝙽̲': 'N', '𝙾̲': 'O', '𝙿̲': 'P', '𝚀̲': 'Q', '𝚁̲': 'R', '𝚂̲': 'S', '𝚃̲': 'T',
    '𝚄̲': 'U', '𝚅̲': 'V', '𝚆̲': 'W', '𝚇̲': 'X', '𝚈̲': 'Y', '𝚉̲': 'Z',
    '𝟶̲': '0', '𝟷̲': '1', '𝟸̲': '2', '𝟹̲': '3', '𝟺̲': '4', '𝟻̲': '5', '𝟼̲': '6', '𝟽̲': '7', '𝟾̲': '8', '𝟿̲': '9',
    '!̲': '!', '@̲': '@', '#̲': '#', '$̲': '$', '%̲': '%', '^̲': '^', '&̲': '&', '*̲': '*', '(̲': '(', ')̲': ')',
    '–̲': '-', '_̲': '_', '=̲': '=', '+̲': '+', '[̲': '[', ']̲': ']', '{̲': '{', '}̲': '}', '|̲': '|',
    ';̲': ';', ':̲': ':', '\'̲': '\'', '"̲': '"', ',̲': ',', '.̲': '.', '<̲': '<', '>̲': '>', '?̲': '?',
    '/̲': '/', '`̲': '`', '~̲': '~', '\\̲': '\\'
  };

  function translateBold(char) {
    let diff;
    if (/[0-9]/.test(char)) {
      return boldDigits[char];
    } else if (/[A-Z]/.test(char)) {
      diff = "𝗔".codePointAt(0) - "A".codePointAt(0);
    } else if (/[a-z]/.test(char)) {
      diff = "𝗮".codePointAt(0) - "a".codePointAt(0);
    } else {
      return char;
    }
    return String.fromCodePoint(char.codePointAt(0) + diff);
  }

  const handleBoldText = () => {
    const editor = quillRef.current.getEditor();
    const range = editor.getSelection();
    if (range) {
      const selectedText = editor.getText(range.index, range.length);
      const translatedText = selectedText.split('').map(char => {
        const charCode = char.codePointAt(0);
        // console.log(`Char: ${char}, Code Point: ${charCode}`);

        if (charCode === 55349) {
          return;
        }
        else if (char === 'ℎ') {
          const ch = 'h';
          return translateBold(ch);
        }
        else if (char === ' ' || char === '') {
          return char;
        }
        else if ((charCode >= 56372 && charCode <= 56423)) {
          const ch = reverseItalicMap[charCode];
          return translateBold(ch);
        }
        else if (reverseBoldMap[charCode]) {
          const normalChar = reverseBoldMap[charCode];
          // const asciiValue = normalChar.charCodeAt(0);
          // console.log(`Inside unbold block: Bold Char - ${char}, Normal Char - ${normalChar}, ASCII - ${asciiValue}`);
          return normalChar;
        } else {
          // console.log('inside else', char, `Code Point: ${charCode}`);
          return translateBold(char);
        }
      }).join('');
      editor.deleteText(range.index, range.length);
      editor.insertText(range.index, translatedText);
      editor.setSelection(range.index, translatedText.length);
    }
  };

  function translateItalic(char) {
    const charCode = char.codePointAt(0);
    // console.log(char,charCode);
    if (charCode === 55349) {
      return;
    }
    if (charCode >= 56372 && charCode <= 56423) {
      return reverseItalicMap[charCode];
    }
    if ((charCode >= 57324 && charCode <= 57333) || (charCode >= 56814 && charCode <= 56839) || (charCode >= 56788 && charCode <= 56813)) {
      char = reverseBoldMap[charCode];
    }
    if (/[A-Za-z]/.test(char)) {
      if (char === 'h') {
        return 'ℎ';
      }
      let diff;
      if (/[A-Z]/.test(char)) {
        diff = "𝐴".codePointAt(0) - "A".codePointAt(0);
      } else {
        diff = "𝑎".codePointAt(0) - "a".codePointAt(0);
      }
      return String.fromCodePoint(char.codePointAt(0) + diff);
    }
    return char;
  }

  const customItalicHandler = () => {
    const editor = quillRef.current.getEditor();
    const selection = editor.getSelection();
    if (selection && selection.length > 0) {
      const selectedText = editor.getText(selection.index, selection.length);
      const italicText = selectedText.split('').map(translateItalic).join('');
      editor.deleteText(selection.index, selection.length);
      editor.insertText(selection.index, italicText);
      editor.setSelection(selection.index, italicText.length);

      // ******* to apply default formatting
      // const newRange = {
      //   index: selection.index,
      //   length: italicText.length
      // };
      // const currentFormat = editor.getFormat(newRange);
      // editor.formatText(newRange.index, newRange.length, 'italic', !currentFormat.italic);
    }
  };

  function translateUnderline(char, index, arr) {
    const unicodeChar = String.fromCodePoint(818);
    const nextChar = arr[index + 1]; 
    // console.log(char,nextChar, char.codePointAt(0));
    if(char===unicodeChar) return;
    if (nextChar === unicodeChar) {
      // console.log('Already underlined:', char);
      return char; 
    }
    if (/[A-Za-z0-9]/.test(char)) {
      // console.log('Adding underline to:', char);
      return char + unicodeChar;
    }
    return char;
  }
  
  const customUnderlineHandler = () => {
    const editor = quillRef.current.getEditor();
    const selection = editor.getSelection();
    if (selection && selection.length > 0) {
      const selectedText = editor.getText(selection.index, selection.length);
      const underlineText = selectedText.split('').map(translateUnderline).join('');
      editor.deleteText(selection.index, selection.length);
      editor.insertText(selection.index, underlineText);
      editor.setSelection(selection.index, underlineText.length);
    }
  };
  
  function translateStrike(char, index, arr) {
    const unicodeChar = String.fromCodePoint(822);
    const nextChar = arr[index + 1]; 
    // console.log(char,nextChar, char.codePointAt(0));
    if(char===unicodeChar) return;
    else if (nextChar === unicodeChar) {
      // console.log('Already underlined:', char);
      return char; 
    }
    if (/[A-Za-z0-9]/.test(char))
      // else
     {
      // console.log('Adding underline to:', char);
      // return char + unicodeChar;
      return char + '\u0336';
    }
    return char;
  }

  const customStrikeHandler = () => {
    const editor = quillRef.current.getEditor();
    const selection = editor.getSelection();
    if (selection && selection.length > 0) {
      const selectedText = editor.getText(selection.index, selection.length);
      const strikeText = selectedText.split('').map(translateStrike).join('');
      editor.deleteText(selection.index, selection.length);
      editor.insertText(selection.index, strikeText);
      editor.setSelection(selection.index, strikeText.length);
    }
  };

  const customBulletHandler = () => {
    // console.log('checking bullet handler');
    if (quillRef && quillRef.current) {
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection();
  
      if (range) {
        const selectedText = quill.getText(range.index, range.length);
        const lines = selectedText.split('\n');
        quill.deleteText(range.index, range.length); 
        let formattedText = lines.map(line => {
          // return line.trim().startsWith('•') ? line : `• ${line}`;
          return `• ${line}`;
        }).join('\n');
        quill.insertText(range.index, formattedText); 
        quill.setSelection(range.index, formattedText.length);
      }
    }
  };

  const customListHandler = () => {
    if (quillRef && quillRef.current) {
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection();
  
      if (range) {
        const selectedText = quill.getText(range.index, range.length);
        console.log('0',selectedText);
        const lines = selectedText.split('\n');
        quill.deleteText(range.index, range.length);
        
        let index = 0;
        let formattedText = lines.map(line => {
          if (line.trim() === '') return ''; 
  
          let cleanedLine = line.trim().replace(/^\s*/, ''); 
  
          if (/^\d+\.\s/.test(cleanedLine)) return cleanedLine;
  
          index += 1;
          return `${index}.  ${cleanedLine}`; 
        }).join('\n');
        // console.log(formattedText)
        quill.insertText(range.index, formattedText);
        quill.setSelection(range.index, formattedText.length);

      }
    }
  };

  return (
    <div id="toolbar">
      <div className="custom-select-container" style={{ position: 'relative', zIndex: 100 }}>
        <div className="custom-select-header" onClick={() => setIsOpen(!isOpen)}>
          <input
            type='text'
            value={selectedDocument}
            onChange={(e) => setSelectedDocument(e.target.value)}
            placeholder='Select the document' />
          <span className={`dropdown-arrow ${isOpen ? 'up' : 'down'}`}></span>
        </div>
        {isOpen && (
          <div className="custom-select-dropdown">
            {filteredDocuments.length > 0 ? (
              filteredDocuments.map((doc) => (
                <div key={doc._id} className="custom-select-item" onClick={() => handleOptionClick(doc)}>
                  {doc.title}
                </div>
              ))
            ) : (
              <div className="custom-select-item">No other documents</div>
            )}
          </div>
        )}
      </div>

      <div className='vertical-line'></div>

      <div className="ql-dashboard effects" onClick={() => navigate('/homepage')}>Dashboard</div>

      <div className='vertical-line'></div>

      <button className="ql-save effects" onClick={loading ? null : handleSaveDocument}>
        {loading ? <Spinner animation="border" size="sm" /> : <>Save</>}
      </button>

      <div className='vertical-line'></div>
      <button className="ql-bold btn" onClick={handleBoldText}>Bold</button>
      {/* <button className="q1-bold btn" >Bold</button>  */}
      <button className="ql-italic btn" onClick={customItalicHandler}>Italic</button>
      {/* <button className="q1-italic btn" >Italic</button> */}
      <button className="ql-underline btn" onClick={customUnderlineHandler}>U</button>
      {/* <button className="ql-underline btn">Underline</button> */}
      <button className="ql-strike btn" onClick={customStrikeHandler}>Strike</button>
      {/* <button className="ql-strike btn">Strike</button> */}
      {/* <button className="ql-blockquote btn">Blockquote</button> */}
      <button className="btn"  onClick={customListHandler}><FontAwesomeIcon  className='list-icon' icon={faListOl} /></button>
      {/* <button className="ql-list btn" value="ordered">Ordered List</button>  */}
      <button className="btn" onClick={customBulletHandler}> <FontAwesomeIcon className='list-icon' icon={faListUl} /></button>
      {/* <button className="ql-list btn" value="bullet">Bullet List</button> */}
      <div className='vertical-line'></div>
      <button className="ql-image btn" >Insert Image</button>
    </div>
  );
};

export default Toolbar;