

import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Image, Dropdown } from 'react-bootstrap';
import './CustomNavbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const CustomNavbar = () => {
  const [user, setUser] = useState(null);
  const [tokens, setTokens] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('authToken');

  const navigate=useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (response.ok) {
          const userData = await response.json();
          setUser(userData);
        } else {
        //  console.error('Failed to fetch user data:', response.statusText);
        }
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [token]);

  useEffect(() => {
    let timeoutId;

    const handleActivity = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleLogout, 15 * 60 * 1000); // 15 minutes in milliseconds
    };

    const handleLogout = () => {
      localStorage.removeItem('authToken');
      window.location.href = '/';
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);

    timeoutId = setTimeout(handleLogout, 15 * 60 * 1000);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
    };
  }, []);

  const handleLogout = async () => {
    localStorage.removeItem('authToken');
    window.location.href = '/';
  };

  const handleGoogleAuth = () => {
    // Open a new popup window for Google authentication
    const width = 500;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const googleAuthPopup = window.open(
      `${process.env.REACT_APP_BACKEND_URL}/auth/google`,
      'GoogleLogin',
      `width=${width},height=${height},top=${top},left=${left}`
    );

    // Set up a listener to receive the token from the popup window
    const receiveMessage = (event) => {
      if (event.origin !== process.env.REACT_APP_BACKEND_URL) return; // Ensure it's coming from the right origin
      const { token, error } = event.data;
      
      if (error) {
        // Handle the error (e.g., display a notification)
        alert(error); // Replace with your preferred notification method
        return;
      }
      
      if (token) {
        // Store the token in localStorage and refresh the main window
        localStorage.setItem('authToken', token);
        window.location.reload(); // Refresh to get user data
      }
    };

    window.addEventListener('message', receiveMessage, false);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  };

  const navigateHome = () => {
    window.location.href = '/';
  };

  const fetchTokens = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/tokens`, {
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch tokens');
      }

      const data = await response.json();
      setTokens({
        tokensAlloted: data.tokensAlloted,
        tokensUsed: data.tokensUsed,
        balanceToken: data.balanceToken,
      });
    } catch (error) {
      console.error('Error fetching tokens:', error);
    }
  };

  const toggleDropdown = (isOpen) => {
    if (isOpen) {
      fetchTokens();
    }
  };

  if (loading) {
    return (
      <Navbar expand="lg" className="custom-navbar">
        <Container>
          <Navbar.Brand>
            <Image 
              src="https://typegrow.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fapple-touch-icon.fe07020f.png&w=48&q=75" 
              style={{ width: '40px', height: '40px' }} 
              alt="hi"
            />
            MagicText
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">Loading...</Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }

  return (
    <Navbar expand="lg" className="custom-navbar">
      <Container>
        <Navbar.Brand href="#">
          <Image 
            src="https://typegrow.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fapple-touch-icon.fe07020f.png&w=48&q=75" 
            style={{ width: '40px', height: '40px' }} 
          />
          MagicText
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
  
          <Nav className="ms-auto">
        <Navbar.Brand onClick={()=>navigate('/privacy')} className='privacy footer-element'>Privacy</Navbar.Brand>
        <Navbar.Brand onClick={()=>navigate('/dpa')} className='dpa footer-element' >Data Processing</Navbar.Brand>
        <Navbar.Brand onClick={()=>navigate('/terms-of-service')} className='terms-of-service footer-element'>Terms of Service</Navbar.Brand>
        <Navbar.Brand onClick={()=>navigate('/help&support')} className='help&support footer-element'>Help & Support</Navbar.Brand>
        <Navbar.Brand  className='refund-policy footer-element'>Refund Policy</Navbar.Brand>
            {user ? (
              <Dropdown onToggle={toggleDropdown}>
                <Dropdown.Toggle as="a" className="nav-link">
                  <Image
                    src={user.picture}
                    roundedCircle
                    width="30"
                    height="30"
                    alt=""
                    style={{ marginRight: '5px' }}
                  />
                  {user.name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {tokens ? (
                    <>
                      <Dropdown.Item className="fw-bold" onMouseDown={(e) => e.preventDefault()}>
                        Tokens Alloted: {tokens.tokensAlloted}
                      </Dropdown.Item>
                      <Dropdown.Item className="fw-bold" onMouseDown={(e) => e.preventDefault()}>
                        Tokens Used: {tokens.tokensUsed}
                      </Dropdown.Item>
                      <Dropdown.Item className="fw-bold" onMouseDown={(e) => e.preventDefault()}>
                        Balance Token: {tokens.balanceToken}
                      </Dropdown.Item>
                    </>
                  ) : (
                    <Dropdown.Item onMouseDown={(e) => e.preventDefault()}>Loading tokens...</Dropdown.Item>
                  )}
                  <Dropdown.Divider />
                  <Dropdown.Item onMouseDown={handleLogout}>
                    <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '8px' }} />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <button variant="outline-light" className="try-button" onClick={handleGoogleAuth}>
                Login with Google
              </button>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;