import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Container, Form, Button, Spinner, Modal } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import './CombinedPanel.css';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { renderToString } from 'react-dom/server';
import Toolbar from './Toolbar';
import Editor from './Editor';
import Accordion from 'react-bootstrap/Accordion';
import CustomNavbar from './CustomNavbar';
import Collapse from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';


const CombinedPanel = () => {
  const { promptId } = useParams();
  const location = useLocation();
  const [prompts, setPrompts] = useState([]);
  const [error, setError] = useState('');
  const [prompt, setPrompt] = useState(null);
  const [variables, setVariables] = useState({});
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activePromptId, setActivePromptId] = useState(null);
  const [activePromptIdRight, setActivePromptIdRight] = useState(null);
  const [currentResponseIndex, setCurrentResponseIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [documentName, setDocumentName] = useState('');
  const [editorContent, setEditorContent] = useState('');
  const [docId, setDocId] = useState('');
  const [description, setDescription] = useState('');
  const [clicks, setClicks] = useState(0); 
  const [showPrompts, setShowPrompts] = useState(true);
  const [openOp, setOpenOp] = useState(true);
// new changes for prop ref
  const quillRef = useRef(null);


  const navigate = useNavigate();
  const headerRefs = useRef({});

  useEffect(() => {
    if (location.state && location.state.doc) {
      // console.log('1', location.state);
      setDocumentName(location.state.doc.title);
      setEditorContent(location.state.doc.content);
      setDocId(location.state.doc._id || location.state.doc.ID);
      setDescription(location.state.doc.description);
    }
  }, [location.state]);

  useEffect(() => {
    if (activePromptId) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [activePromptId]);


  const token = localStorage.getItem('authToken');
  useEffect(() => {
    const fetchPrompts = async () => {
      try {
        setError('');

        const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/userprompts`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the headers
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });
        // console.log(result.data)
        setPrompts(result.data);
      } catch (error) {
      //  console.error(error);
        setError('Failed to fetch prompts');
      }
    };
    fetchPrompts();
  }, []);

  useEffect(() => {
    const fetchPrompt = async () => {
      try {
        setError('');

        const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/prompts/${promptId}`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the headers
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });
        setPrompt(result.data);
      } catch (error) {
        console.error('Error fetching prompt:', error);
        setError('Error fetching prompt');
      }
    };
    fetchPrompt();
  }, [promptId]);

  const handleVariableChange = (name, value) => {
    setVariables({ ...variables, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!prompt) return;
    window.fbq('track', 'PromptUsed');
    const variableArray = Object.keys(variables).map(variableName => ({
      name: variableName,
      value: variables[variableName],
    }));

    setResponses([]);
    setLoading(true);
    setError('');
    setClicks(clicks + 1);

    try {
      const results = await Promise.all([

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/prompts/${promptId}/execute`, { variables: variableArray }, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the headers
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }),
      ]);

      // Check if the response contains a statusCode indicating insufficient tokens
      results.forEach(res => {
        if (res.data.response && res.data.response.statusCode === 403) {
          console.log("Status Code:", res.data.response.statusCode);
          alert(res.data.response.message); // Show alert if free tokens are insufficient
          return; // Exit if tokens are insufficient
        }
      });

      // Set the responses state with mapped content if tokens are sufficient
      setResponses(results.map(res => res.data.response.content));
      setCurrentResponseIndex(0);
    } catch (error) {
      console.error('Error generating response:', error);
      setError('Error generating response');
    } finally {
      setLoading(false);
    }

  };

  const openResponse = (id) => {
    if (activePromptId === id) {
      setActivePromptId(null);
      return;
    }
    setClicks(0);
    setActivePromptId(id);
    setActivePromptIdRight(id);
    // Scroll the clicked accordion header into view
    if (headerRefs.current[id]) {
      headerRefs.current[id].scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    navigate(`/response/${id}`);
  };

  const handleResponseClick = (index) => {
    if (responses[index] === undefined) return;
    setCurrentResponseIndex(index);

    const renderedMarkdown = (
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        {responses[index]}
      </ReactMarkdown>
    );

    const markdownHTML = renderToString(renderedMarkdown);
    if (!editorContent)
      setEditorContent(`${markdownHTML}`);
    else
      setEditorContent(`${editorContent}${markdownHTML}`);
  };

  return (
    <>
      <CustomNavbar />
      <div style={{ height: '64px' }}></div>
      

       <Toolbar 
        documentName={documentName} 
        setDocumentName={setDocumentName}
        editorContent={editorContent} 
        setEditorContent={setEditorContent} 
        docId={docId} 
        setDocId={setDocId} 
        quillRef={quillRef}
         /> 

      <Container fluid className="my-0 mx-0 d-flex flex-row parent-div" >

        {/* Left Section: Prompt Section */}
        <FontAwesomeIcon
          icon={showPrompts ? faChevronLeft : faChevronRight}
          className='prompt-icon icon'
          onClick={() => setShowPrompts(!showPrompts)}
        />


        <div 
        // className="d-flex flex-column hide-scroll prompt-section "
        className={`d-flex flex-column prompt-section ${showPrompts ? '' : 'hide'}`}
        >
          <style>
            {`
      .custom-input::placeholder {
        color: black; /* Placeholder text color */
        opacity: 0.5; /* Opacity for placeholder */
      }
    `}
          </style>

          {showPrompts && (
            <Accordion defaultActiveKey={activePromptId}>
              {prompts.map((prompt) => (
                <Accordion.Item eventKey={prompt._id} key={prompt._id}>
                  <Accordion.Header
                    ref={(el) => (headerRefs.current[prompt._id] = el)} // Attach ref to each header
                    onClick={() => openResponse(prompt._id)}
                  >
                    {prompt.title}
                  </Accordion.Header>
                  <Accordion.Body style={{ border: '2px solid #CFE2FF' }}>
                    {activePromptId === prompt._id && (
                      <div>
                        {/* Form and other elements */}
                        {prompt && (
                          <Form onSubmit={handleSubmit} className="">
                            {prompt.variables.map((variable) => (
                              <Form.Group key={variable.name}>
                                <Form.Label>{variable.name}:</Form.Label>
                                <Form.Control
                                  type="text"
                                  onChange={(e) => handleVariableChange(variable.name, e.target.value)}
                                  required
                                  placeholder={variable.placeholder} // Placeholder from backend
                                  className='mb-1 custom-input' // Add custom-input class for styling
                                  style={{
                                    backgroundColor: 'white', // Input background color
                                    color: 'black', // Input text color
                                  }}
                                />
                              </Form.Group>
                            ))}
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '5px' }}>
                              <button
                                type="submit"
                                className="w-100 generate-button"
                              >
                                {loading ? <Spinner animation="border" size="sm" /> : clicks === 0 ? 'Generate' : 'Regenerate'}
                              </button>
                            </div>
                          </Form>
                        )}
                        {error && <div style={{ color: 'red', marginTop: '20px' }}>{error}</div>}
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          )}
        </div>



        {/* Middle Section: Text Editor */}
        <div className="d-flex flex-column editor-section"
         style={{ 
          // flex: showPrompts ? '2' : '3', padding: '0 10px', display: 'flex', flexDirection: 'column', transition: 'flex 0.3s ease', 
           flex: showPrompts && openOp ? '2' : openOp ? '3' : showPrompts ? '3' : '4'
          }}>
           <Editor
            editorContent={editorContent}
            setEditorContent={setEditorContent}
            quillRef={quillRef}
          /> 
          
          {/* <TextEditor /> */}
         

        </div>

        {/* Right Section: Form Inputs and Buttons */}
        {
          openOp && <div 
          // className='right-section'
          className={`d-flex flex-column right-section ${openOp ? '' : 'hide'}`}
          >
            {activePromptIdRight ? (
              <>
                <div
                  className="d-flex flex-column ">

                  {/* Output Section */}

                  <div
                    // className="hide-scroll "
                    style={{
                      height: '78.5vh',
                      marginTop: '0px',
                      marginBottom: '0px',
                      padding: '10px',
                      // backgroundColor: '#f0f0f0',
                      borderRadius: '5px',
                      // overflowY: 'auto',
                      position: 'relative',
                      // border :'2px solid blue',
                      // background:'yellow'
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', }}>

                      <span style={{ fontWeight: 'bold', margin: 'auto' }}>Response </span>

                      {/* <button className='move-to-editor-btn' onClick={() => handleResponseClick(currentResponseIndex)}>Move to editor</button> */}
                    </div>

                    {responses.length > 0 && (
                      <div
                        className="hide-scroll"
                        style={{
                          padding: '10px',
                          backgroundColor: '#fff',
                          borderRadius: '5px',
                          // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                          cursor: 'pointer',
                          background: '#D9FEE5',
                          // border:'2px solid blue',
                          height: '70.5vh'
                        }}
                      >
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                          {responses[currentResponseIndex]}
                        </ReactMarkdown>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="text-center mt-5 "  >
                <p style={{ color: '#555', fontWeight: 'bold', color: 'green', }}>Please select a prompt to start generating responses.</p>
              </div>
            )}
          </div>
        }
        <FontAwesomeIcon
          icon={openOp ? faChevronRight : faChevronLeft}
          className='op-icon icon'
          onClick={() => setOpenOp(!openOp)}
        />


      </Container>
    </>
  );
};

export default CombinedPanel;
